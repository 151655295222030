<template lang="pug">
  .choose-course-day
    base-list-selectable(
      :value="city"
      @input="selectCity"
      :list="cities"
      slug-key="ID"
    ).choose-course-day__cities
      template(#top)
        .top-filters
          .select-date
            appDatepicker(
              label="Choose date"
              :value="date"
              :class="['select-date', {'active': date}]"
              @input="selectDate"
              @change="selectDate"
              @clear="selectDate(undefined)"
              no-title
              :min="getMinDate"
              :max="getMaxDate"
            )
              template(v-slot:activator)
                span.calendar-icon
        .separator
        choose-course-tabs(@click:tab="selectTab" :activeTabName="activeTab")
      template(v-slot:item="{item, selected, select}")
        li(slot="item" :class="{'selected': selected}" @click="select").choose-course-day__city.unselectable
          .choose-course-day__code {{ item.code }}
          span.choose-course-day__city-name {{ item.name }}
    .c-course-days
      .course-days__items
        .c-course-days__item(v-if="groupedCourseDays.length" v-for="(groupedCourseDay, index) in groupedCourseDays" :key="index")
          div.c-course-days__date.calendar-date
            div {{ groupedCourseDay.date }}
            .calendar-date__subtitle {{ groupedCourseDay.month }}
          .c-course-days__wrapper
            app-card(v-for="courseDay in groupedCourseDay.items" :key="courseDay.id")
              course-day(
                :courseDay="courseDay"
                :city="city"
                :key="courseDay.id"
                :meetingSpaces="meetingSpaces"
                @update="updateItem"
                @click.native="selectCourseDay(courseDay)"
                @removed="updateItem"
              )
        .c-course-days__empty(v-if="!groupedCourseDays.length")
          p No data available

      intersect(@enter="loadCourseDays(true)" :treshhold="[0, 0.5]" v-if="!loading && groupedCourseDays.length")
        div.intersection-observer
      div(v-if="loading")
        base-preloader.text-primary
</template>

<script>
import { convertToDefaultBackendFormat, convertToShortMonth } from '@/util'
import { LOAD_DEFAULT_CITY_ID } from '@/util/const.js'
import { COURSE_DAY_FILTERS, COURSE_DAY_SIDEBAR_TABS } from '../core/courseDay-const'
import BaseListSelectable from '@/components/global/selectedList/BaseListSelectable'
import CourseService from '@/app/admin/models/courseService'
import PaginationModel from '@/models/paginationModel'
import api from '@/services/api/api-courses'
import errorsMixin from '@/mixins/errors.mixin'
import Intersect from 'vue-intersect'

export default {
  name: 'CourseDaysList',

  mixins: [errorsMixin],

  props: {
    cities: Array,
    meetingSpaces: Array,
    activeCity: Number,
    showing: Boolean
  },

  data: () => ({
    city: null,
    date: null,
    courseDays: [],
    loading: false,
    groupedCourseDays: [],
    courseSvc: new CourseService(api),
    LOAD_DEFAULT_CITY_ID,
    COURSE_DAY_FILTERS,
    pagination: {},
    activeTab: COURSE_DAY_SIDEBAR_TABS.FUTURE
  }),

  computed: {
    getMinDate() {
      if (this.activeTab === COURSE_DAY_SIDEBAR_TABS.FUTURE) return convertToDefaultBackendFormat(new Date(Date.now()))
      else return ''
    },
    getMaxDate() {
      let date = new Date();
      if (this.activeTab === COURSE_DAY_SIDEBAR_TABS.PAST) return convertToDefaultBackendFormat(date.setDate(date.getDate() - 1))
      else return ''
    }
  },

  methods: {
    async updateItem(payload) {
      if (payload) {
        let selectedItem = this.groupedCourseDays.find(item => item.items[0].id === payload.id)
        if (!selectedItem) return
        selectedItem = selectedItem.items[0]
        for (let field in selectedItem) {
          if (field in payload.data) {
            selectedItem[field] = payload.data[field]
          }
        }
      }
    },

    async selectCity(value) {
      this.city = value
      this.$emit('update:city', value)
      await this.startFiltering()
    },

    async selectDate(value) {
      if (this.date === value) return
      if (!value) value = undefined
      this.date = value

      this.$emit('update:date', value)
      await this.startFiltering()
    },

    async startFiltering() {
      this.courseDays = []
      this.groupedCourseDays = []
      this.pagination = new PaginationModel({page: 1, size: 20})
      await this.loadCourseDays()
      this.groupCourseDays(this.courseDays)
    },

    async loadCourseDays(more = false) {
      try {
        if (this.loading || this.pagination.isLastPage) return

        let results, pagination
        this.loading = true

        if (more) this.pagination.loadMore()

        let query = {
          city: this.city.ID,
          date: this.date,
          page: this.pagination.page,
          size: this.pagination.size
        }
        //
        if (this.activeTab === COURSE_DAY_SIDEBAR_TABS.PAST) {
          let res = await this.courseSvc.courseDaysPast(query, 20)
          results = res.results
          pagination = res.pagination
        }
        else {
          let res = await this.courseSvc.courseDaysFuture(query, 20)
          results = res.results
          pagination = res.pagination
        }
        //
        this.pagination = new PaginationModel({...pagination, page: pagination.page, size: pagination.size})

        if (more) this.courseDays.push(...results)
        else this.courseDays = results

        this.groupCourseDays(this.courseDays)
        this.loading = false
      } catch (e) {
        this.processError(e, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      } finally {
        this.loading = false
      }
    },

    async selectTab(value) {
      this.activeTab = value
      if (this.date) this.date = null
      await this.startFiltering()
    },

    groupCourseDays(courseDays) {
      let hashMap = new Map()
      this.groupedCourseDays = []
      courseDays.forEach(courseDay => {
        if (hashMap.has(courseDay.date)) {
          hashMap.set(courseDay.date, {
            ...hashMap.get(courseDay.date),
            items: [...hashMap.get(courseDay.date).items, courseDay]
          })
        } else hashMap.set(courseDay.date, {
          date: new Date(courseDay.date).getDate(),
          month: convertToShortMonth(courseDay.date),
          items: [courseDay]
        })
      })
      for (let [, value] of hashMap) {
        this.groupedCourseDays.push(value)
      }
    },

    selectCourseDay(courseDay) {
      this.$emit('click:course-day', courseDay)
    },

    selectBaseCity() {
      if (!this.cities.length) return

      if (this.activeCity) {
        let city = this.cities.find(city => city.ID === this.activeCity)
        return this.selectCity(city)
      } else this.selectCity(this.cities[this.LOAD_DEFAULT_CITY_ID])
    },

    resetFilters() {
      this.date = null
      this.activeTab = COURSE_DAY_SIDEBAR_TABS.FUTURE
      this.selectBaseCity()
    }
  },

  watch: {
    cities: {
      handler: function () {
        this.selectBaseCity()
      },
      immediate: true
    },

    'activeCity'() {
      this.selectBaseCity()
    },

    'showing'() {
      if (!this.showing) this.resetFilters()
    }
  },

  components: {
    Intersect,
    BaseListSelectable,
    courseDay: () => import('./CourseDayItem.vue'),
    appCard: () => import('@/components/global/BaseCard.vue'),
    BasePreloader: () => import('@/components/global/BasePreloader.vue'),
    appDatepicker: () => import('@/components/global/fields/appDatepicker'),
    ChooseCourseTabs: () => import('./body/ChooseCourseTabs.vue')
  }
}
</script>

<style lang="scss">
.select-date {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .app-datepicker {
    margin: 5px;

    &.active {
      .calendar-icon {
        transition: all 0.15s ease-in-out;
        background: url("../../../../../assets/img/ui/calendar-black.svg") no-repeat;
      }
    }

    .calendar-icon {
      min-width: 15px;
      min-height: 15px;
      margin-right: 5px;
      background-size: contain;
      transition: all 0.15s ease-in-out;
      background: url("../../../../../assets/img/ui/calendar-grey.svg") no-repeat;
    }

    .close-icon {
      right: -8px;
      top: -5px;
    }
  }
}

.intersection-observer {
  height: 10px;
}

.choose-course-day {
  display: flex;

  &__cities {
    width: 180px;
    border-right: 1px solid $bg-filled;
  }

  &__code {
    display: inline-block;
    margin-right: 8px;
    min-width: 16px;
    min-height: 16px;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    background-color: #F4F7F9;
    color: #000000;
    vertical-align: middle;
    text-align: center;
    font-weight: 900;
    font-size: 7px;
    line-height: 16px;
  }

  &__city {
    position: relative;
    display: flex;
    align-items: center;
    padding: 6.5px 12px;
    font-weight: 500;
    font-size: 12px;
    cursor: pointer;

    &-name {
      display: inline-block;
      vertical-align: middle;
      line-height: 1;
    }

    &:hover {
      color: $primary-color;
    }

    &.selected {
      background-color: $primary-color;
      color: #FFFFFF;

      &::after,
      &::before {
        position: absolute;
        right: 0;
        left: 0;
        height: 4px;
        background-color: $primary-color;
        content: "";
      }

      &::after {
        top: -4px;
      }

      &::before {
        bottom: -4px;
      }

      &:first-child::after {
        display: none;
      }

      &:last-child::before {
        display: none;
      }
    }
  }
}


.c-course {
  &-days {
    padding: 0 10px 20px 0;
    width: 100%;
    background: #F4F7F9;

    &__item {
      position: relative;
      display: flex;
      padding-top: 20px;
      padding-right: 6px;
      padding-left: 6px;

      .app-card {
        margin-top: 6px;
        width: 100%;
        cursor: pointer;

        &:hover {
          box-shadow: 0 2px 8px 0 rgba($primary-color, 0.2);
        }

        &:first-child {
          margin-top: 0;
        }
      }

      &:first-child:before {
        display: none;
      }

      &::before {
        position: absolute;
        top: 0;
        left: 24px;
        margin: 0 auto;
        width: 1px;
        height: 20px;
        background-color: #DFE7EB;
        content: "";
      }

      &::after {
        position: absolute;
        top: 57px;
        bottom: 0;
        left: 24px;
        margin: 0 auto;
        width: 1px;
        height: 100%;
        background-color: #DFE7EB;
        content: "";
      }

      &:last-child::after {
        display: none;
      }
    }

    &__empty {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 20px;
      width: 100%;

      p {
        color: $label-color;
        font-size: 13px;
        font-family: $font-roboto;
        line-height: normal;
      }
    }

    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }

    &__date {
      position: relative;
      margin-right: 6px;
      padding: 6px;
      width: 37px;
      height: 37px;
      border-radius: 4px;
      background: #FFFFFF;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);
      color: $title-second;
      text-align: center;
      font-weight: 700;
      font-size: 16px;
      line-height: 1;
    }

    .calendar-date {
      &__subtitle {
        margin-top: 2px;
        color: $label-color;
        font-weight: bold;
        font-size: 8px;
      }
    }
  }
}
</style>

