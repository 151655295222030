<template lang="pug">
  .base-list__selectable
    slot(name="top")
    ul.app-list
      list-item(
        v-for="(item, index) in selectableList"
        :item="item"
        :index="index"
        :key="item.ID"
        @click:select="selectItem"
      )
        template(v-slot:default="{select, selected}")
          slot(name="item" :item="item" :index="index" :select="select" :selected="selected")
</template>

<script>
export default {
  name: 'ListSelectable',

  props: {
    list: {
      required: true,
      type: Array
    },
    value: {
      required: true
    },
    slugKey: String
  },

  data: () => ({
    selectableList: [],
  }),

  methods: {
    selectItem(index) {
      this.unselectAll()
      this.$emit('input', this.list[index])
    },

    unselectAll() {
      this.selectableList.forEach(item => item.selected = false)
    }
  },

  watch: {
    list: {
      handler: function () {
        this.selectableList = this.list.map(item => {
          return {...item, selected: false}
        })
      },
      immediate: true
    },

    value: {
      handler: async function (newValue, oldValue) {
        if (!this.value) return
        this.unselectAll()
        let changedItemIndex = this.selectableList.findIndex(item => item[this.slugKey] === this.value[this.slugKey])
        if (changedItemIndex === -1) return
        if (this.selectableList[changedItemIndex].selected) return
        await this.$nextTick()
        this.selectableList[changedItemIndex].selected = true
      },
      immediate: true
    }
  },

  components: {
    listItem: () => import('./BaseListSelectableItem.vue')
  }
}
</script>

<style lang="scss">
.app-list {
  list-style-type: none;
  padding-left: 0 !important;
}
</style>
